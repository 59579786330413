<template>
  <b-form-group
    label="Müşteri Tipi"
    label-for="customer_type"
  >
    <validation-provider
      #default="{ errors }"
      name="Müşteri Tipi"
      rules="required"
    >
      <v-select
        id="customer_type"
        v-model="customer.id_com_customer_type"
        label="title"
        :reduce="type => type.id"
        :options="customerTypes"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'CustomerType',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      locale: 'tr',
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    customerTypes() {
      return this.$store.getters['customerTypes/getCustomer_types']
    },
  },
  created() {
    localize(this.locale)
    this.getCustomerTypes()
  },
  methods: {
    getCustomerTypes() {
      this.$store.dispatch('customerTypes/customer_typesList', {
        select: ['com_customer_type.id AS id', 'com_customer_type.title AS title'],
      })
    },
  },
}
</script>

<style scoped></style>
