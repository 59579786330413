<template>
  <b-sidebar
    id="sidebar-invoice-add-new-customer"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Müşteri Kartı Ekle
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer ref="simpleRules">
        <b-form
          class="p-2"
        >
          <b-row>
            <b-col md="12">
              <customerType />
            </b-col>
            <b-col
              v-if="customer.id_com_customer_type === '2'"
              md="12"
            >
              <tax-number />
              <companyName />
            </b-col>
            <b-col
              v-if="customer.id_com_customer_type === '1'"
              md="12"
            >
              <tckn />
              <nameSurname />
            </b-col>
            <b-col md="12">
              <phone />
            </b-col>
            <b-col md="12">
              <email :is-required="false" />
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <saveButton
              :kvkk-require="false"
              :action-methods="submitForm"
            />
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BSidebar, BForm, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import saveButton from '@/views/Customers/forms/SaveButton.vue'
import Tckn from '@/views/Customers/forms/Tckn.vue'
import TaxNumber from '@/views/Customers/forms/TaxNumber.vue'
import nameSurname from '@/views/Customers/forms/NameSurname.vue'
import companyName from '@/views/Customers/forms/CompanyName.vue'
import customerType from '@/views/Customers/forms/CustomerType.vue'
import phone from '@/views/Customers/forms/Phone.vue'
import email from '@/views/Customers/forms/Email.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BRow,
    BCol,
    saveButton,
    Tckn,
    TaxNumber,
    nameSurname,
    companyName,
    customerType,
    phone,
    email,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    refreshData: {
      type: Function,
    },
  },
  data() {
    return {
      locale: 'tr',
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
      submitStatus: false,
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    saveStatus() {
      return this.$store.getters['customers/getCustomerSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          id_com_brand: null,
          id_com_location: null,
          sdate: null,
          edate: null,
          count: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.refreshData()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.customer.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
    this.$store.commit('customers/RESET_CUSTOMER_FORM')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitStatus = true
          this.customer.submitStatus = true
          this.$store.dispatch('customers/customerSave', this.customer)
        }
      })
    },
  },
}
</script>
